import { useCallback, useEffect, useMemo, useState } from "react";
import useBanner from "../../../../../hooks/useBanner";
import parseObjectToQueryUrl from "../../../../../util/parseObjectToQueryUrl";
import useGetSurveyAnsweredPaginateQuery from "../../../../../hooks/Queries/useGetSurveyAnsweredPaginateQuery";
import { useTranslation } from "react-i18next";
import CellDate from "../../../../Table/CellDate";
import CellActions from "../../../../Common/CellActions";
import { addIconClass, deleteIconClass, editIconClass, viewIconClass } from "../../../../constants/icons";
import { Button, Col, Row } from "reactstrap";
import TableContainer from "../../../../Common/TableContainer";
import PaginationManual from "../../../../Common/PaginationManual";
import Loader from "../../../../Common/Loader";
import BasicModal from "../../../../Common/BasicModal";
import FormSurveyAnswered from "./Preview/FormSurveyAnswered";
import DeleteModal from "../../../../Common/DeleteModal";
import { useMutation } from "react-query";
import { deleteSurveyAnswered } from "../../../../../helpers/catalogues/surveyAnswer";
import { DELETE_SUCCESS } from "../../../../constants/messages";

const initFilter = {
	reservationId: '',
	surveyId: '',
	customerId: '',
};

const CardsSurveyClient = ({ customerId=null, reservationId=null }) => {
    const banner = useBanner();
    const { t } = useTranslation('translation', {
        keyPrefix: 'components.operation.surveyAnswer',
    });
    const { t: tMConstant } = useTranslation('translation', {
        keyPrefix: 'constants.language',
    });
    const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
    const [item, setItem] = useState(null);
	const [showModal, setShowModal] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [query, setQuery] = useState({
        ...initFilter,
        max: 10,
        page: 1,
        customerId,
        reservationId
    });
    const [queryFilter, setQueryFilter] = useState(parseObjectToQueryUrl(query));

    const toggleModal = () => setShowModal(!showModal);

    const configQuery = {
		keepPreviousData: true,
	};
    const {
		data: itemsData,
		error: errorItemsQuery,
		isError: isErrorQuery,
		isFetching,
		isSuccess,
        refetch
	} = useGetSurveyAnsweredPaginateQuery({
		queryPath: queryFilter,
		configQuery,
	});

    useEffect(() => {
        if (isErrorQuery) {
            banner.simpleError(errorItemsQuery);
        }
    }, [errorItemsQuery, banner, isErrorQuery]);

    const editItem = useCallback((row) => {
        const { original } = row;
        setItem({
            id: original.id ?? null,
            anwserQuestions: original.anwserQuestions ?? [],
            customer: original.customer ?? null,
            reservation: original.reservation ?? null,
            survey: original?.survey ?? null,
            readOnly: false
        });
        setShowModal(true);
    }, [])

    const viewItem = useCallback((row) => {
        const { original } = row;
        setItem({
            id: original.id ?? null,
            anwserQuestions: original.anwserQuestions ?? [],
            customer: original.customer ?? null,
            reservation: original.reservation ?? null,
            survey: original?.survey ?? null,
            readOnly: true
        });
        setShowModal(true);
    }, [])

    const showDialogDelete = useCallback((row) => {
		setShowDeleteDialog(true);
		const { original } = row;
		setItem({ id: original.id });
	}, []) 

    const columns = useMemo(
		() => [
            {
				Header: t('dateCreated'),
				accessor: 'dateCreated',
				filterable: false,
				style: {
					width: '15%',
				},
                Cell: CellDate
			},
			{
				Header: t('survey'),
				accessor: 'survey.title',
				filterable: false,
				style: {
					width: '35%',
				},
			},
            {
				Header: t('language'),
				accessor: 'survey.lang',
				filterable: false,
				style: {
					width: '10%',
				},
                Cell: ({ value }) => tMConstant(value)
			},
            {
				Header: 'Version',
				accessor: 'survey.version',
				filterable: false,
				style: {
					width: '10%',
				},
			},
			{
				Header: t('enabled'),
				accessor: 'survey.active',
				filterable: false,
				style: {
					width: '10%',
				},
				Cell: ({ value }) => (
					<span className="fw-bold text-center">
						{value === 0 ? t('no') : t('yes')}
					</span>
				),
			},
			{
				id: 'action',
				style: {
					width: '5%',
				},
				Cell: ({ row }) => {
					return (
						<CellActions
							actions={[
                                {
									iconClass: `${viewIconClass} fs-5 text-info`,
									click: viewItem,
									labelTooltip: t('view'),
								},
								{
									iconClass: `${editIconClass} fs-5 text-primary`,
									click: editItem,
									labelTooltip: t('edit'),
								},
                                {
                                    iconClass: `${deleteIconClass} fs-5 text-danger`,
                                    click: showDialogDelete,
                                    labelTooltip: t('delete'),
                                }
							]}
							row={row}
						/>
					);
				},
			},
		],
		[t, tMConstant, editItem, showDialogDelete, viewItem]
	);

    const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
		mutationFn: deleteSurveyAnswered,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(DELETE_SUCCESS));
			refetch();
			setShowDeleteDialog(false);
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

    const handleDelete = async () => {
		const dataToDelete = {
			id: item.id,
		};
		deleteItem(dataToDelete);
	};
    
    if (isFetching) return <Loader />;
    if (!isFetching && isSuccess) {
        return (
            <>
                <Row className="mb-3 align-items-center">
					<Col xs="12" md={10}></Col>
					<Col xs="12" md={2}>
						<div className="d-flex align-items-center justify-content-end gap-2">
							<Button
								color="info"
								size="sm"
								onClick={toggleModal}
								className="d-flex align-items-center"
							>
								<i className={`${addIconClass} fs-5`} />{' '}
								{t('add')}
							</Button>
						</div>
					</Col>
				</Row>
                <TableContainer
                    columns={columns}
                    data={isSuccess ? itemsData?.data?.list ?? [] : []}
                    className="custom-header-css"
                    divClass="table-responsive mb-3"
                    tableClass="align-middle table-wrap"
                    theadClass=""
                />
                <PaginationManual
                    query={query}
                    setQuery={setQuery}
                    setQueryFilter={setQueryFilter}
                    totalPages={itemsData?.data?.pagination?.totalPages ?? 1}
                    showTotal={true}
                    totalCount={itemsData?.data?.pagination?.totalCount ?? 0}
                    isLoading={isFetching}
                />
                <BasicModal
					open={showModal}
					setOpen={setShowModal}
					title={t('newSurvey')}
					size="lg"
					children={
						<FormSurveyAnswered
							closeModal={toggleModal}
							customerId={customerId}
                            reservationId={reservationId}
                            item={item}
						/>
					}
				/>
                <DeleteModal
                    handleDelete={handleDelete}
                    show={showDeleteDialog}
                    setShow={setShowDeleteDialog}
                    isDeleting={isDeleting}
                />
            </>
        )
    }

   
}

export default CardsSurveyClient