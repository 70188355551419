import QuestionLineal from "./QuestionLineal";
import QuestionOptionMultiple from "./QuestionOptionMultiple";
import QuestionOptionSingle from "./QuestionOptionSingle";
import QuestionText from "./QuestionText";

const QuestionHandle = ({
  question,
  onHandleLinealAnswer,
  onHandleMultipleAnswer,
  onHandleIndividualAnswer,
  onHandleTextAnswer,
  anwserQuestions
}) => {
  switch (question.questionType.name) {
    case "Lineal":
      return (
        <QuestionLineal
          question={question}
          onHandleLinealAnswer={onHandleLinealAnswer}
          anwserQuestions={anwserQuestions}
        />
      );
    case "OpcionMultiple":
      return (
        <QuestionOptionMultiple
          question={question}
          onHandleMultipleAnswer={onHandleMultipleAnswer}
          anwserQuestions={anwserQuestions}
        />
      );
    case "SeleccionIndividual":
      return (
        <QuestionOptionSingle
          question={question}
          onHandleIndividualAnswer={onHandleIndividualAnswer}
          anwserQuestions={anwserQuestions}
        />
      );
    case "Texto":
      return (
        <QuestionText
          question={question}
          onHandleTextAnswer={onHandleTextAnswer}
          anwserQuestions={anwserQuestions}
        />
      );
    default:
      return null;
  }
};

export default QuestionHandle;
