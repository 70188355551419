import { useQuery } from "react-query";
import { getSurveyQuestionTypes } from "../../helpers/catalogues/survey";
import { useTranslation } from "react-i18next";

const useGetQuestionTypes = ({
	enabled = true,
	full = true,
	configQuery = {},
}) => {
    const { t: tMConstant } = useTranslation('translation', {
            keyPrefix: 'constants.questionType',
    });
    const query = useQuery(
		['getSurveyQuestionTypes'],
		getSurveyQuestionTypes,
		{
			enabled: enabled,
			select: (result) => {
				if (full) {
					return result;
				} else {
					return (
						result.data.list.map((it) => ({
							value: it.id,
							label: tMConstant(it.name),
						})) ?? []
					);
				}
			},
			...configQuery,
		}
	);

	return query;
}

export default useGetQuestionTypes