import { useEffect, useState, useCallback } from 'react';
import { Card, CardBody, Col, Container, Row, Badge } from 'reactstrap';
import TableContainer from '../../../Components/Common/TableContainer';
import Loader from '../../../Components/Common/Loader';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import DetailCanvas from '../../../Components/Common/DetailCanvas';
import moment from 'moment';
import PaginationManual from '../../../Components/Common/PaginationManual';
import parseObjectToQueryUrl from '../../../util/parseObjectToQueryUrl';
import CardHeaderGlobal from '../../../Components/Common/CardHeaderGlobal';
import FilterCommandGlobal from '../../../Components/Common/FilterCommandGlobal';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../../../common/globalsProp';
import useBanner from '../../../hooks/useBanner';
import useGetVentasPaginate from '../../../hooks/Queries/useGetVentasPaginate';
import jsFormatNumber from '../../../util/jsFormatNumber';
import VentasFilter from '../../../Components/Operation/Ventas/VentasFilter';
import useGetVentasById from '../../../hooks/Queries/useGetVentasById';
import { clickToCall } from '../../../helpers/customer';
import useGetCardTypesAllQuery from '../../../hooks/Queries/useGetCardTypesAllQuery';

const initFilter = {
	//reserva
	booking: '',
	status: '',
	certificate: '',
	callCenter: '',
	lastName: '',
	firstName: '',
	country: '',
	state: '',
	email: '',
	movil: '',
	registedDateStart: '',
	registedDateEnd: '',
	program: '',
	segment: '',
	campaing: '',
	onlyCertificates: '',
};
const initFilterModel = {
	statusModel: null,
	programModel: null,
	callCenterModel: null,
	segmentModel: null,
	campaingModel: null,
};

const Ventas = () => {
	const { t, i18n } = useTranslation('translation', {
		keyPrefix: 'pages.sell.list',
	});
	document.title = t('header');
	const banner = useBanner();
	const [idItem, setIdItem] = useState(null);
	const [query, setQuery] = useState({
		max: 10,
		page: 1,
		...initFilter,
	});
	const [queryFilter, setQueryFilter] = useState(
		parseObjectToQueryUrl(query)
	);
	//service
	const {
		data: sellData,
		error: errorSellQuery,
		isLoading,
		isSuccess,
		isFetching,
	} = useGetVentasPaginate(queryFilter);

	const [filterDialog, setFilterDialog] = useState(false);
	const [dataSelect, setDataSelect] = useState(initFilterModel);
	//detail canva
	const [showDetail, setShowDetail] = useState(false);
	const [info, setInfo] = useState(null);
	const { data: cardTypesOpt } = useGetCardTypesAllQuery();

	const toggleFilter = () => {
		setFilterDialog(!filterDialog);
	};

	const builtDetailCanvas = useCallback(
		(item) => {
			//console.log(item);
			const header = {
				title: {
					label: `${t('idBooking')}: `,
					value: item?.idBooking,
				},
				img: null,
				body: [],
			};
			const detailSell = {
				id: 'detailSell',
				title: t('sellDetail'),
				collapse: false,
				body: [
					{
						label: t('saleDate'),
						value: item?.saleDate
							? moment
									.utc(item?.saleDate)
									.local()
									.format(DATE_FORMAT)
							: '',
					},
					{
						label: t('packageName'),
						value: item?.campaign?.name ?? '',
					},
					{
						label: 'Call center',
						value: item?.callCenter?.name ?? '',
					},
					{
						label: t('isCertificate'),
						value: item?.isCertificate ? t('yes') : t('no'),
					},
					{
						label: t('balance'),
						value: jsFormatNumber(item?.balance),
					},
					{
						label: t('status'),
						value:
							i18n.language === 'es'
								? item?.reservationStatus?.status
								: item?.reservationStatus?.statusEng,
					},
					{
						label: t('downPayment'),
						value: jsFormatNumber(item?.downPayment),
					},
					{
						label: t('numberPayment'),
						value: item?.numberOfPayments ?? '',
					},
					{
						label: t('cardType'),
						value:
							cardTypesOpt?.find(
								(it) => it.value === item?.cardType
							)?.label ??
							item?.cardType ??
							'',
					},
					{
						label: t('recordNumber'),
						value: item?.phoneRecordId ?? '',
					},
				],
			};
			const arrayEmails = [];
			if (item?.customer?.email) {
				arrayEmails.push({
					text: item?.customer?.email,
					iconClasses: 'ri-mail-line text-danger',
					action: null,
				});
			}
			if (item?.customer?.email2) {
				arrayEmails.push({
					text: item?.customer?.email2,
					iconClasses: 'ri-mail-line text-danger',
					action: null,
				});
			}
			const arrayPhones = [];
			if (item?.customer?.phone1) {
				arrayPhones.push({
					text: item?.customer?.phone1,
					iconClasses: 'ri-phone-line text-primary',
					action: () => clickToCall(item?.customer.id, 'phone1'),
				});
			}
			if (item?.customer?.phone2) {
				arrayPhones.push({
					text: item?.customer?.phone2,
					iconClasses: 'ri-phone-line text-primary',
					action: () => clickToCall('phone2'),
				});
			}
			if (item?.customer?.phone3) {
				arrayPhones.push({
					text: item?.customer?.phone3,
					iconClasses: 'ri-phone-line text-primary',
					action: () => clickToCall('phone3'),
				});
			}
			if (item?.customer?.movil) {
				arrayPhones.push({
					text: item?.customer?.movil,
					iconClasses: 'ri-phone-line text-primary',
					action: () => clickToCall('movil'),
				});
			}
			const detailCliente = {
				id: 'detailCliente',
				title: t('ownerDetail'),
				collapse: true,
				body: [
					{
						label: t('name'),
						value: `${item?.customer?.firstName} ${
							item?.customer?.lastName ?? ''
						}`,
					},
					{
						label: t('email'),
						value: arrayEmails,
					},
					{
						label: t('phone'),
						value: arrayPhones,
					},
					{
						label: t('maritalStatus'),
						value: item?.maritalStatus ?? '',
					},
				],
			};
			const detailOperacion = {
				id: 'detailOperacion',
				title: t('operationDetail'),
				collapse: true,
				body: [
					{
						label: t('seller'),
						value: item?.user ?? '',
					},
					{
						label: t('checker'),
						value: item?.verifier ?? '',
					},
					{
						label: t('supervisor'),
						value: item?.supervisor,
					},
					{
						label: t('commission'),
						value: item?.comissionAgent ?? '',
					},
					{
						label: t('commission'),
						value: item?.comissionVerifier ?? '',
					},
				],
			};
			const obj = {
				title: `${t('idBooking')}: ${item?.idBooking}`,
				header: header,
				items: [detailSell, detailCliente, detailOperacion],
				goToView: null,
			};
			return obj;
		},
		[t, i18n, cardTypesOpt]
	);

	//service to get the venta
	const {
		data: ventaData,
		isFetching: isFetchingItem,
		error: errorDetail,
	} = useGetVentasById(idItem);
	useEffect(() => {
		console.log(ventaData);
		if (ventaData && !isFetchingItem) {
			const data = builtDetailCanvas(ventaData);
			setInfo(data);
		}
	}, [ventaData, isFetchingItem, builtDetailCanvas]);

	const columns = useMemo(
		() => [
			{
				Header: 'Booking',
				accessor: 'idBooking',
				filterable: false,
				width: '7%',
			},
			{
				Header: t('saleDate'),
				accessor: 'saleDate',
				filterable: false,
				width: '8%',
				Cell: ({ value }) =>
					value ? moment.utc(value).local().format(DATE_FORMAT) : '',
			},
			{
				Header: t('packageName'),
				accessor: 'campaign.name',
				filterable: false,
				width: '20%',
			},
			{
				Header: t('name'),
				accessor: 'customer.firstName',
				filterable: false,
				width: '12%',
				Cell: ({ row, value }) =>
					`${value.toUpperCase()} ${row.original.customer.lastName.toUpperCase()}`,
			},
			{
				Header: 'Call center',
				accessor: 'callCenter.name',
				filterable: false,
				width: '15%',
			},
			{
				Header: t('isCertificate'),
				accessor: 'isCertificate',
				filterable: false,
				style: {
					width: '5%',
				},
				Cell: ({ value }) => (
					<Badge color={value ? 'success' : 'danger'}>
						{value ? t('yes') : t('no')}
					</Badge>
				),
			},
			{
				Header: t('balance'),
				accessor: 'balance',
				style: {
					width: '8%',
				},
				Cell: ({ value }) => jsFormatNumber(value),
			},
			{
				Header: t('status'),
				accessor: 'reservationStatus.status',
				filterable: false,
				width: '7%',
				Cell: ({ row, value }) =>
					i18n.language === 'es'
						? value
						: row.original.reservationStatus.statusEng,
			},
			{
				id: 'action',
				width: '5%',
				Cell: ({ row }) => {
					return (
						<ul className="list-inline hstack mb-0">
							<li
								className="list-inline-item edit"
								title={t('preview')}
							>
								<Link
									to="#"
									className="text-muted d-inline-block"
								>
									<i
										className="ri-file-search-fill fs-16 text-secondary"
										onClick={() => {
											setIdItem(row.original.idBooking);
											setShowDetail(true);
										}}
									></i>
								</Link>
							</li>
							<li
								className="list-inline-item edit"
								title={t('play')}
							>
								{row.original.recordFile ? (
									<a
										href={row.original.recordFile}
										target="_blank"
										rel="noreferrer"
									>
										<i
											className={`ri-play-circle-line fs-16 text-success`}
										/>
									</a>
								) : (
									<i
										className={`ri-play-circle-line fs-16 text-muted`}
									/>
								)}
							</li>
						</ul>
					);
				},
			},
		],
		[t, i18n]
	);

	useEffect(() => {
		if (errorSellQuery?.code) {
			banner.simpleError(errorSellQuery);
		}
	}, [errorSellQuery, banner]);

	const buscar = () => {
		setFilterDialog(false);
		const copyQuery = { ...query, page: 1 };
		setQueryFilter(parseObjectToQueryUrl(copyQuery));
		setQuery(copyQuery);
	};

	const onCleanFilter = () => {
		setFilterDialog(false);
		const copyQuery = { max: 10, page: 1, ...initFilter };
		setQueryFilter(parseObjectToQueryUrl(copyQuery));
		setQuery(copyQuery);
		setDataSelect(initFilterModel);
	};
	return (
		<>
			<div className="page-content">
				<Container fluid>
					<Row>
						<Col xxl={12}>
							<Card className="shadow">
								<CardHeaderGlobal title={t('title')} />
								<CardBody className="pt-0">
									<FilterCommandGlobal
										toggleFilter={toggleFilter}
										onCleanFilter={onCleanFilter}
									/>
									<div>
										{!isLoading ? (
											<>
												<TableContainer
													columns={columns}
													data={
														isSuccess
															? sellData?.data
																	?.list ?? []
															: []
													}
													className="custom-header-css"
													divClass="table-responsive mb-3"
													tableClass="align-middle"
												/>
												<PaginationManual
													query={query}
													setQuery={setQuery}
													setQueryFilter={
														setQueryFilter
													}
													totalPages={
														sellData?.data
															?.pagination
															?.totalPages ?? 1
													}
													showTotal={true}
													totalCount={
														sellData?.data
															?.pagination
															?.totalCount ?? 0
													}
													isLoading={isFetching}
													labelForItem={{
														plural: t('sells'),
														singular: t('sell'),
													}}
												/>
											</>
										) : (
											<Loader />
										)}
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
			<VentasFilter
				show={filterDialog}
				onCloseClick={() => setFilterDialog(false)}
				query={query}
				setQuery={setQuery}
				buscar={buscar}
				dataSelect={dataSelect}
				setDataSelect={setDataSelect}
				onCleanFilter={onCleanFilter}
			/>
			<DetailCanvas
				show={showDetail}
				onCloseClick={() => {
					setShowDetail(false);
				}}
				data={info}
				error={errorDetail}
				isLoading={isFetchingItem}
			/>
		</>
	);
};

export default Ventas;
