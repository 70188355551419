import { del, get, post, put } from '../api_helper';
import * as url from '../url';


const getSurveyAnsweredPaginate = async (query) => await get(`${url.answer}${query}`);
const createSurveyAnswered = async (data) => await post(`${url.answer}`, data);
const deleteSurveyAnswered = async (data) => await del(`${url.answer}/${data.id}`);
const updateSurveyAnswered = async (data) => await put(`${url.answer}/${data.id}`, data.body);

export {
    getSurveyAnsweredPaginate,
    createSurveyAnswered,
    deleteSurveyAnswered,
    updateSurveyAnswered
};