import { useFormik } from 'formik';
import Select from 'react-select';
import { Button, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
	FIELD_REQUIRED,
	SAVE_SUCCESS,
	SELECT_OPTION,
} from '../../../../constants/messages';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import useBanner from '../../../../../hooks/useBanner';
import InputMask from 'react-input-mask';
import useGetCardTypesAllQuery from '../../../../../hooks/Queries/useGetCardTypesAllQuery';
import creditCardType from 'credit-card-type';
import { getCardFlag } from '../../../../../util/getCardFlag';
import { createCard } from '../../../../../helpers/cards';
import ButtonsLoader from '../../../../Loader/ButtonsLoader';

const FormCard = ({
	closeModal,
	customerId,
	isFromReservation = false,
	refetch = () => {},
}) => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.operation.FormCard',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const queryClient = useQueryClient();
	const banner = useBanner();
	const { data: cardTypesOpt } = useGetCardTypesAllQuery();

	//add card
	const { mutate: createCardMutation, isLoading } = useMutation(createCard, {
		onSuccess: () => {
			queryClient.refetchQueries({
				queryKey: ['getCardsByCustomerId'],
			});
			banner.simpleSuccess(tMessage(SAVE_SUCCESS));
			closeModal();
			refetch();
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const formik = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
			cardNumber: '',
			cardExpiry: '',
			cardCvv: '',
			name: '',
			lastName: '',
			customerId: customerId,
			cardType: '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required(tMessage(FIELD_REQUIRED)),
			lastName: Yup.string().required(tMessage(FIELD_REQUIRED)),
			cardNumber: Yup.string().required(tMessage(FIELD_REQUIRED)),
			cardExpiry: Yup.string().required(tMessage(FIELD_REQUIRED)),
			cardType: Yup.string().required(tMessage(FIELD_REQUIRED)),
		}),
		onSubmit: async (values) => {
			createCardMutation(values);
		},
	});

	return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.stopPropagation();
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row>
				<Col lg={isFromReservation ? 3 : 6}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="name">
							{t('name')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.name ? 'is-invalid' : ''
							}`}
							id="name"
							name="name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.name}
						/>
						{formik.errors.name && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.name}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={isFromReservation ? 3 : 6}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="lastName">
							{t('lastName')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.lastName ? 'is-invalid' : ''
							}`}
							id="lastName"
							name="lastName"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.lastName}
						/>
						{formik.errors.lastName && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.lastName}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={isFromReservation ? 3 : 12}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="cardNumber">
							{t('card')}
						</Label>
						<div className="input-group">
							<InputMask
								id="cardNumber"
								className={`form-control ${
									formik.errors.cardNumber ? 'is-invalid' : ''
								}`}
								mask="9999 9999 9999 9999"
								maskChar=" "
								placeholder="xxxx xxxx xxxx xxxx"
								value={formik.values.cardNumber}
								onChange={(e) => {
									let val = e.target.value.replace(
										/[^0-9]/g,
										''
									);
									formik.setFieldValue('cardNumber', val);
									//checamos q tarjeta es
									const types = creditCardType(val);
									if (val === '') {
										formik.setFieldValue('cardType', '');
									} else if (types.length === 1) {
										const type = types[0].niceType;
										const cardType =
											cardTypesOpt.find((it) =>
												it.label
													.replace(/\s+/g, '')
													.toLowerCase()
													.includes(
														type
															.replace(/\s+/g, '')
															.toLowerCase()
													)
											)?.value ?? '';
										formik.setFieldValue(
											'cardType',
											cardType
										);
									}
								}}
							/>
							<div className="input-group-text bg-light text-dark">
								{getCardFlag(formik.values.cardNumber)}
							</div>
						</div>

						{formik.errors.cardNumber && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.cardNumber}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={isFromReservation ? 3 : 12}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="cardType">
							{t('cardType')}
						</Label>
						<Select
							value={
								formik.values.cardType
									? {
											value: formik.values.cardType,
											label:
												cardTypesOpt?.find(
													(it) =>
														it.value ===
														formik.values.cardType
												)?.label ?? '',
									  }
									: null
							}
							onChange={(value) => {
								formik.setFieldValue('cardType', value.value);
							}}
							options={cardTypesOpt}
							name="choices-single-default"
							placeholder={tMessage(SELECT_OPTION)}
							id="cardType"
						/>
						{formik.errors.idCardType && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.idCardType}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={isFromReservation ? 3 : 8}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="cardExpiry">
							{t('expirationDate')}
						</Label>
						<InputMask
							id="cardExpiry"
							className={`form-control ${
								formik.errors.cardExpiry ? 'is-invalid' : ''
							}`}
							mask="99/99"
							maskChar=" "
							placeholder="MM/YY"
							value={formik.values.cardExpiry}
							onChange={(e) => {
								formik.setFieldValue(
									'cardExpiry',
									e.target.value
								);
							}}
						/>
						{formik.errors.cardExpiry && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.cardExpiry}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={isFromReservation ? 2 : 4}>
					<div className="mb-2">
						<Label className="form-label mb-1" htmlFor="cardCvv">
							CVV
						</Label>
						<InputMask
							id="cardCvv"
							className={`form-control ${
								formik.errors.cardCvv ? 'is-invalid' : ''
							}`}
							mask="999"
							maskChar=" "
							placeholder="xxx"
							value={formik.values.cardCvv}
							onChange={(e) => {
								formik.setFieldValue('cardCvv', e.target.value);
							}}
						/>
						{formik.errors.cardCvv && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.cardCvv}
							</FormFeedback>
						)}
					</div>
				</Col>
			</Row>
			{isLoading ? (
				<div className="d-flex my-3">
					<ButtonsLoader
						buttons={[
							{
								text: t('ok'),
								color: 'primary',
								className: 'me-2',
								loader: true,
							},
							{
								text: t('cancel'),
								color: 'danger',
								className: 'btn-soft-danger',
								loader: false,
							},
						]}
					/>
				</div>
			) : (
				<div className="d-flex mt-3">
					<Button
						type="button"
						onClick={() => {
							formik.submitForm();
						}}
						color="primary"
						className="me-2"
					>
						{t('ok')}
					</Button>
					<Button
						type="button"
						color="danger"
						className="btn-soft-danger"
						onClick={closeModal}
					>
						{t('cancel')}
					</Button>
				</div>
			)}
		</Form>
	);
};

export default FormCard;
