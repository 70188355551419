import { useTranslation } from 'react-i18next';
import phoneRing from '../../../../assets/images/gif/phoneRing.gif';

const ClickToCallAlert = ({ closeToast, toastProps, user }) => {
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'components.clickToCallAlert',
	});
	return (
		<div>
			<div className="d-flex align-items-center mb-3 justify-content-between mt-2">
				<div className="d-flex flex-column">
					<h5 className="mb-2">{tMessage('title')}</h5>
					<small>
						<strong>{tMessage('extension')}:</strong>{' '}
						{user.extension}
					</small>
					<small>
						<strong>{tMessage('provider')}:</strong> {user.provider}
					</small>
					<small>
						<strong>{tMessage('server')}:</strong>{' '}
						{user.server ?? ''}
					</small>
				</div>
				<div>
					<img
						src={phoneRing}
						alt="LLamando"
						className="rounded"
						height={62}
						width={62}
					/>
				</div>
			</div>
			<i
				className="mdi mdi-close-thick position-absolute fs-5"
				style={{ top: '2%', right: '2%' }}
				onClick={closeToast}
			/>
		</div>
	);
};

export default ClickToCallAlert;
