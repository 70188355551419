import moment from "moment"
import { DATE_TIME_FORMAT } from "../../common/globalsProp"

const CellDate = ({ value }) => {

    return (
        <span>
            {
                value
                ? moment.utc(value).local().format(DATE_TIME_FORMAT)
                : ''
            }
        </span>
    )
}

export default CellDate