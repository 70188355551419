import { useEffect, useState } from "react";
import { FaRegStar, FaStar } from "react-icons/fa6";

const QuestionLineal = ({ question, onHandleLinealAnswer, anwserQuestions }) => {
  const [selected, setSelected] = useState(-1);


    useEffect(() => {
        const findIndex = anwserQuestions.findIndex(it=>it.question.id === question.id)
        if(findIndex !== -1){
            setSelected(anwserQuestions[findIndex].answerResponse)
        }
    }, [anwserQuestions, question.id])

  return (
    <div className="d-flex flex-column align-items-center" style={{ gap: '4px'}}>
      <h6 className="mb-0">
        {question.title}
      </h6>
      <div className="d-flex flex-wrap justify-content-center" style={{ gap: '8px'}}>
        {question.options
          .sort((a, b) => a - b)
          .map((option) => (
            <label
              key={option}
              onClick={() => {
                setSelected(option);
                onHandleLinealAnswer(option, question);
              }}
              className={`d-flex align-items-center justify-content-center m-0`}
            >
              {parseInt(option) <= parseInt(selected) ? (
                <FaStar className={`fs-2 text-warning m-0`} />
              ) : (
                <FaRegStar className={`fs-2 text-warning m-0`} />
              )}
            </label>
          ))}
      </div>
    </div>
  );
};

export default QuestionLineal;
