import { useEffect, useState } from "react";

const QuestionOptionMultiple = ({ question, onHandleMultipleAnswer, anwserQuestions }) => {
    const [checkedOpt, setCheckedOpt] = useState([])

    useEffect(() => {
        const findIndex = anwserQuestions.findIndex(it=>it.question.id === question.id)
        if(findIndex !== -1){
            const arr = anwserQuestions[findIndex].answerResponse.split(",")
            setCheckedOpt(arr)
        }
    }, [anwserQuestions, question.id])
    
    return (
      <div className="d-flex flex-column align-items-center" style={{ gap: '4px'}}>
       <h6 className="mb-0">{question.title}</h6>
        <div className="d-flex justify-content-center" style={{ gap: '8px'}}>
          {question.options.map((option) => (
            <label key={option} className={`d-flex`} style={{ gap: '3px'}}>
              <input
                className="text-primary"
                type="checkbox"
                checked={checkedOpt.includes(option)}
                onChange={(e) => {
                  onHandleMultipleAnswer(option, e.target.checked, question)
                  if(!e.target.checked){
                    setCheckedOpt(checkedOpt.filter(it=>it!==option))
                  }else{
                    setCheckedOpt(prev=>[...prev, option])
                  }
                  
                }}
              />
              <span className="text-muted" style={{ fontSize: '12px'}}>{option}</span>
            </label>
          ))}
        </div>
      </div>
    );
  };
  
  export default QuestionOptionMultiple;
  