import { useQuery } from 'react-query';
import { getSurveyAnsweredPaginate } from '../../helpers/catalogues/surveyAnswer';

const useGetSurveyAnsweredPaginateQuery = ({
	queryPath,
	enabled = true,
	configQuery = {},
}) => {
	const query = useQuery(
		['getSurveyAnsweredPaginate', queryPath],
		() => getSurveyAnsweredPaginate(`?${queryPath}`),
		{
			enabled: Boolean(queryPath) && enabled,
			select: (result) => result,
			...configQuery,
		}
	);

	return query;
};

export default useGetSurveyAnsweredPaginateQuery;
