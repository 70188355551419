import { useQuery } from "react-query";
import { getDepartamentList } from "../../helpers/configuration/departament";

const useGetDepartments = () => {

    const query = useQuery(
		['getDepartamentList'],
		async () => {
			const response = await getDepartamentList();
			return response;
		},
		{
			select: (response) =>
				response.data.list.map((it) => ({
					value: it.id,
					label: it.name,
				})),
		}
	);

    return query
}

export default useGetDepartments