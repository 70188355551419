import { useEffect, useState } from "react";

const QuestionOptionSingle = ({ question, onHandleIndividualAnswer, anwserQuestions }) => {
  const [selected, setSelected] = useState(-1);
  useEffect(() => {
        const findIndex = anwserQuestions.findIndex(it=>it.question.id === question.id)
        if(findIndex !== -1){
            setSelected(anwserQuestions[findIndex].answerResponse)
        }
    }, [anwserQuestions, question.id])
    return (
      <div className="d-flex flex-column align-items-center" style={{ gap: '4px'}}>
        <h6 className="mb-0">{question.title}</h6>
        <div className="d-flex justify-content-center" style={{ gap: '8px'}}>
          {question.options.map((option) => (
            <label key={option} className={`d-flex`} style={{ gap: '3px'}}>
              <input
                className="text-primary"
                type="radio"
                name={`radio-${question.id}`}
                checked={selected === option}
                onChange={() => {
                  onHandleIndividualAnswer(option, question)
                  setSelected(option)
                }}
              />
              <span className="text-muted" style={{ fontSize: '12px'}}>{option}</span>
            </label>
          ))}
        </div>
      </div>
    );
  };
  
  export default QuestionOptionSingle;
  