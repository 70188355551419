export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const ERROR_SERVER = 'ERROR_SERVER';
export const FIELD_REQUIRED = 'FIELD_REQUIRED';
export const SELECT_OPTION = 'SELECT_OPTION';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_QUESTION_CONFIRMATION = 'DELETE_QUESTION_CONFIRMATION';
export const FIELD_NUMERIC = 'FIELD_NUMERIC';
export const CORREO_VALID = 'CORREO_VALID';
export const FIELD_INTEGER = 'FIELD_INTEGER';
export const FIELD_POSITIVE = 'FIELD_POSITIVE';
export const FIELD_GREATER_THAN_CERO = 'FIELD_GREATER_THAN_CERO';
export const FIELD_MAX_LENGTH = 'FIELD_MAX_LENGTH';
export const SMS_SUCCESS = 'SMS_SUCCESS';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const ONE_OPTION_REQUIRED = 'ONE_OPTION_REQUIRED';
export const MISSING_CLIENTS = 'MISSING_CLIENTS';
export const ASSIGN_CLIENTS_SUCCESS = 'ASSIGN_CLIENTS_SUCCESS';
export const FIELD_TOP_30 = 'FIELD_TOP_30';
export const SEND_CONFIRMATION_LETTER_SUCCESS =
	'SEND_CONFIRMATION_LETTER_SUCCESS';
export const QUESTION_CONFIRMATION = 'QUESTION_CONFIRMATION';
