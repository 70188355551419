import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query"
import useBanner from "../../../hooks/useBanner";
import useGetDepartments from "../../../hooks/Queries/useGetDepartments";
import { createSurvey, updateSurvey } from "../../../helpers/catalogues/survey";
import { FIELD_INTEGER, FIELD_MAX_LENGTH, FIELD_NUMERIC, FIELD_POSITIVE, FIELD_REQUIRED, ONE_OPTION_REQUIRED, SAVE_SUCCESS, SELECT_OPTION, UPDATE_SUCCESS } from "../../constants/messages";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import * as Yup from 'yup';
import useUser from "../../../hooks/useUser";
import { Button, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { languageOpt } from "../../constants/utils";
import ButtonsLoader from "../../Loader/ButtonsLoader";
import useGetQuestionTypes from "../../../hooks/Queries/useGetQuestionTypes";
import { deleteIconClass } from "../../constants/icons";

const FormSurvey = ({item, toggleModal }) => {
    const queryClient = useQueryClient()
    const user = useUser()
    const { t } = useTranslation('translation', {
		keyPrefix: 'components.formSurvey',
	});
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
    const { t: tMConstant } = useTranslation('translation', {
		keyPrefix: 'constants.language',
	});
	const banner = useBanner();

    const { data: departmentsOpt } = useGetDepartments()
    const { data: questionTypesOpt } = useGetQuestionTypes({ full: false })

    //create survey
	const { mutate: createItem, isLoading: isCreating } = useMutation({
		mutationFn: createSurvey,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(SAVE_SUCCESS));
			toggleModal();
            queryClient.refetchQueries({queryKey: ['getSurveyPaginate']})
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

    //update survey
	const { mutate: updateItem, isLoading: isUpdating } = useMutation({
		mutationFn: updateSurvey,
		onSuccess: () => {
			banner.simpleSuccess(tMessage(UPDATE_SUCCESS));
			toggleModal();
            queryClient.refetchQueries({queryKey: ['getSurveyPaginate']})
		},
		onError: (error) => {
			banner.simpleError(error);
		},
	});

    const formik = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		initialValues: {
            id: item?.id ?? null,
			title: item?.title ?? '',
            active: item?.active ?? true,
			description: item?.description ?? '',            
            lang: item?.lang ?? 'es',
			departments: item?.departments ?? [],
            questions: item?.questions ?? [{
                title: "",
                options: [""],
                sorder: 0,
                activo: true,
                questionType: null
            }],
            createdBy: user.usuario
		},
		validationSchema: Yup.object({
			title: Yup.string().required(tMessage(FIELD_REQUIRED)),
			description: Yup.string().max(250,`${tMessage(FIELD_MAX_LENGTH)} 250`),
            lang: Yup.string().required(tMessage(FIELD_REQUIRED)),
            departments: Yup.array().min(1, tMessage(ONE_OPTION_REQUIRED)),
            questions: Yup.array()
            .of(
                Yup.object().shape({
                    title: Yup.string().required(tMessage(FIELD_REQUIRED)),
                    sorder: Yup.number().min(0, tMessage(FIELD_POSITIVE)).integer(tMessage(FIELD_INTEGER)).typeError(tMessage(FIELD_NUMERIC)).required(tMessage(FIELD_REQUIRED)),
                    questionType: Yup.object().shape({
                        id: Yup.string().required(tMessage(FIELD_REQUIRED)),
                    }),
                })
            )
            .min(1, tMessage(ONE_OPTION_REQUIRED)),
		}),
		onSubmit: async (values) => {
			//submit request
			if (item?.id) {
				updateItem({
					id: item.id,
					body: values,
				});
			} else {
				createItem(values);
			}
		},
	});
    return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row>
				<Col lg={8}>
					<div className="mb-2">
						<Label className="form-label mb-0" htmlFor="title">
							{t('title')}
						</Label>
						<Input
							type="text"
							className={`form-control ${
								formik.errors.title ? 'is-invalid' : ''
							}`}
							id="title"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.title}
						/>
						{formik.errors.title && (
							<FormFeedback type="invalid d-block">
								{formik.errors.title}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col lg={4}>
					<div className="mb-2">
                        <Label className="form-label mb-0" htmlFor="motivo">
							{t('language')}
						</Label>
						<Select
							id="motivo"
							className="mb-0"
							value={formik.values.lang ? {value: formik.values.lang, label: tMConstant(formik.values.lang)} : null}
							onChange={(value) => formik.setFieldValue('lang', value.value)}
							options={languageOpt.map((it) => ({
								...it,
								label: tMConstant(it.label),
							}))}
							placeholder={tMessage(SELECT_OPTION)}
						/>
                        {formik.errors.lang && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.lang}
							</FormFeedback>
						)}
					</div>
				</Col>
                <Col lg={8}>
                  <div className="mb-2">
                        <Label className="form-label mb-0" htmlFor="description">
                        {t('description')}
                        </Label>
                        <textarea
                            id="description"
                            name="description"
                            className={`form-control`}
                            value={formik.values.description}
                            onChange={(e) =>
                                formik.setFieldValue(
                                    'description',
                                    e.target.value
                                )
                            }
                            rows={4}
                        />
                        {formik.errors.description && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.description}
							</FormFeedback>
						)}
                  </div>   
                </Col>
                <Col lg={4}>
					<div className="mb-2">
						<Label
							className="form-label mb-0"
							htmlFor="departments"
						>
							{t('departments')}
						</Label>
						<Select
							id="departments"
							className="mb-0"
							value={formik.values.departments.map((it) => ({
								label: it.name,
								value: it.id,
							}))}
							onChange={(value) => {
								formik.setFieldValue(
									'departments',
									value.map((it) => ({
										name: it.label,
										id: it.value,
									}))
								);
							}}
							options={departmentsOpt}
							isMulti={true}
							placeholder={tMessage(SELECT_OPTION)}
						/>
                        {formik.errors.departments && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.departments}
							</FormFeedback>
						)}
					</div>
				</Col>				
			</Row>
            <h6 className="mt-1">Preguntas</h6>
            <div className="mb-5">
                <FormikProvider value={formik}>
                <FieldArray
                    name="questions"
                    render={(arrayHelper) => (
                        <div className="d-flex flex-column">
                            <div className="p-2">
                                {
                                    formik.values.questions.map((_, idx) => (
                                        <Row key={`question-${idx}`} className="mb-1 bg-light">
                                            <Col xs="12" md="8">
                                                <div className="mb-2">
                                                    <Label className="form-label mb-0" htmlFor="title">
                                                        {t('title')}
                                                    </Label>
                                                    <Field
                                                        className={`form-control ${formik.errors?.questions?.length > 0 && formik.errors?.questions[idx]?.title ? 'is-invalid' : '' }`}
                                                        name={`questions.${idx}.title`}
                                                    />                                        
                                                    {formik.errors?.questions?.length > 0 && formik.errors?.questions[idx]?.title && (
                                                        <FormFeedback type="invalid" className="d-block">
                                                            {formik.errors?.questions[idx]?.title}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col xs="12" md="2">
                                                <div className="mb-2">
                                                    <Label className="form-label mb-0" htmlFor="title">
                                                        {t('order')}
                                                    </Label>
                                                    <Field
                                                        className={`form-control ${formik.errors?.questions?.length > 0 && formik.errors?.questions[idx]?.sorder ? 'is-invalid' : '' }`}
                                                        name={`questions.${idx}.sorder`}
                                                        type="number"
                                                    />                                        
                                                    {formik.errors?.questions?.length > 0 && formik.errors?.questions[idx]?.sorder && (
                                                        <FormFeedback type="invalid" className="d-block">
                                                            {formik.errors?.questions[idx]?.sorder}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col xs="12" md="2">
                                                <div className="mb-2 form-check mt-3">
                                                    <Field
                                                        className={`form-check-input`}
                                                        name={`questions.${idx}.activo`}
                                                        type="checkbox"
                                                        id={`questions.${idx}.activo`}
                                                    />   
                                                    <Label className="form-check-label" htmlFor={`questions.${idx}.active`}>
                                                        {t('active')}
                                                    </Label> 
                                                </div>
                                            </Col>
                                            <Col xs="12" md="12">
                                                <Row>
                                                    <Col xs="12" md="3">
                                                        <div className="mb-2">
                                                            <Label className="form-label mb-0">
                                                                {t('questionType')}
                                                            </Label>
                                                            <Select
                                                                value={formik.values.questions[idx].questionType
                                                                        ? { value: formik.values.questions[idx].questionType.id, 
                                                                            label: questionTypesOpt?.find(it => it.value === formik.values.questions[idx].questionType.id)?.label ?? ''}
                                                                        : null
                                                                }
                                                                onChange={(value) => {
                                                                    formik.setFieldValue(`questions.${idx}.questionType.id`, value.value);
                                                                }}
                                                                options={questionTypesOpt}
                                                                classNamePrefix="select2-selection"
                                                                placeholder={tMessage(SELECT_OPTION)}
                                                            />                                   
                                                            {formik.errors?.questions?.length > 0 && formik.errors?.questions[idx]?.questionType?.id && (
                                                                <FormFeedback type="invalid d-block">
                                                                    {formik.errors?.questions[idx]?.questionType?.id}
                                                                </FormFeedback>
                                                            )}
                                                        </div>
                                                    </Col>
                                                    <Col xs="12" md="9">
                                                      <div className="mb-2">
                                                            <Label className="form-label mb-0">
                                                                {t('options')}
                                                            </Label>
                                                            <FieldArray 
                                                                name={`questions.${idx}.options`}
                                                                render={(optionHelper) => (
                                                                    <div className="d-flex flex-column">
                                                                        <Row>
                                                                            {
                                                                                formik.values.questions[idx].options.map((_, idxOption) => (
                                                                                    <Col xs="6" md="3" key={`question-${idx}-option-${idxOption}`} className="mb-1">
                                                                                        <div className="position-relative">
                                                                                            <Field
                                                                                                className={`form-control`}
                                                                                                name={`questions.${idx}.options.${idxOption}`}
                                                                                            />
                                                                                            <i
                                                                                                className={`${deleteIconClass} fs-5 position-absolute end-0 text-danger cursor-pointer`}
                                                                                                style={{ top: '10px'}}
                                                                                                onClick={() => optionHelper.remove(idxOption)}
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                ))
                                                                            }
                                                                        </Row>
                                                                        <div className="mt-1">
                                                                        <Button
                                                                            type="button"
                                                                            color="link"
                                                                            className="me-2"
                                                                            size="sm"
                                                                            onClick={(e) => {
                                                                                optionHelper.push("");
                                                                            }}
                                                                        >
                                                                            {t('addOption')}
                                                                        </Button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            />
                                                      </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" md="12">
                                               <div className="pb-1">
                                                <Button
                                                    type="button"
                                                    color="danger"
                                                    outline
                                                    size="sm"
                                                    onClick={() => arrayHelper.remove(idx)}
                                                    className="d-flex ms-auto"
                                                >
                                                    <i
                                                        className={`${deleteIconClass} fs-5`}
                                                    />
                                                    Eliminar pregunta
                                                </Button>            
                                               </div> 
                                            </Col>
                                        </Row>
                                    ))   
                                }
                            </div>
                            <div className="d-flex flex-row mt-1">
                                <Button
                                    type="button"
                                    color="light"
                                    className="me-2"
                                    size="sm"
                                    onClick={(e) => {
                                        arrayHelper.push({
                                            title: "",
                                            options: [],
                                            sorder: formik.values.questions.length,
                                            activo: true,
                                            questionType: null
                                        });
                                    }}
                                >
                                    {t('addAnother')}
                                </Button>                                
                            </div>
                        </div>
                                            
                    )}
                />
                </FormikProvider>
            </div>
            <hr />
			{(isCreating || isUpdating) ? (
				<div className="d-flex my-3">
					<ButtonsLoader
						buttons={[
							{
								text: t('accept'),
								color: 'primary',
								className: 'me-2',
								loader: true,
							},
							{
								text: t('cancel'),
								color: 'danger',
								className: 'btn-soft-danger',
								loader: false,
							},
						]}
					/>
				</div>
			) : (
				<div className="d-flex my-3">
					<Button type="submit" color="primary" className="me-2">
						{t('accept')}
					</Button>
					<Button
						type="button"
						color="danger"
						className="btn-soft-danger"
						onClick={toggleModal ? toggleModal : () => {}}
					>
						{t('cancel')}
					</Button>
				</div>
			)}
		</Form>
	);
}

export default FormSurvey