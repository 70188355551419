import { useEffect, useState } from "react";

const QuestionText = ({ question, onHandleTextAnswer, anwserQuestions }) => {
  const [text, setText] = useState('');
  useEffect(() => {
      const findIndex = anwserQuestions.findIndex(it=>it.question.id === question.id)
      if(findIndex !== -1){
        setText(anwserQuestions[findIndex].answerResponse)
      }
  }, [anwserQuestions, question.id])
    return (
      <div className="d-flex flex-column align-items-center" style={{ gap: '4px'}}>
        <h6 className="mb-0">{question.title}</h6>
        <textarea
          className={`form-control`}
          rows={3}
          value={text}
          onChange={(e) => {
            onHandleTextAnswer(e.target.value, question)
            setText(e.target.value)
          }}
        />
      </div>
    );
  };
  
  export default QuestionText;
  